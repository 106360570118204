<template>
  <div class="preview-box">
    <span
      class="no-print print-preview material-symbols-outlined icon-yellow icon-link"
      @click="print()"
    >print</span>
    <v-icon
    class="no-print close-preview material-symbols-outlined icon-yellow icon-link"
      @click="goBack()"
    >cancel</v-icon>
    <div class="preview-inner">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10">
          <h1>View Invoice</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-2 medium-offset-10">
          <img src="../../assets/logo.png" alt="Dice" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-1">
          To:
        </div>
        <div class="cell small-12 medium-5">
          {{ invoice.accounts_contact }}
        </div>
        <div class="cell small-12 medium-2">Invoice no: </div>
        <div class="cell small-12 medium-4 text-right">{{ invoice.id }}</div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          {{ invoice.client.name }}
        </div>
        <div class="cell small-12 medium-2">
          Invoice date:
        </div>
        <div class="cell small-12 medium-4 text-right">
          {{ invoice.created | tinyDate }}
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          {{ invoice.address.address_1 }}
          <span v-if="invoice.address.address_2"><br />{{ invoice.address.address_2 }}</span>
          <span v-if="invoice.address.address_3"><br />{{ invoice.address.address_3 }}</span>
          <span v-if="invoice.address.address_4"><br />{{ invoice.address.address_4 }}</span>
          <span v-if="invoice.address.address_5"><br />{{ invoice.address.address_5 }}</span>
          <span v-if="invoice.address.postcode"><br />{{ invoice.address.postcode }}</span>
          <span v-if="invoice.address.country"><br />{{ invoice.address.country }}</span>
        </div>
        <div class="cell small-12 medium-2">
          Our Job No:<br />
          Your order No:<br />
          Ordered by:
        </div>
        <div class="cell small-12 medium-4 text-right">
          {{ invoice.job_number.job_number }}<br />
          {{ invoice.estimate.po_number }}<br />
          {{ invoice.estimate.contact.email }}
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          Description:
        </div>
        <div class="cell small-12 medium-6">
          Amount:
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          {{ invoice.estimate.brand.name }}
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          {{ invoice.job_number.description }}
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 medium-6">
          <p class="pb-4">
            Payment of all invoices is due within 30 days of the invoice date. Dice Medical
            Communications Ltd. reserves the right to invoice an interest charge of 3% per
            week on overdue amounts. Such invoices are payable within 7 days of the invoice date.
          </p>
          <p>
            For BACS payments please quote the following<br />
            Bank: NATWEST BANK PLC<br />
            Account name: DICE MEDICAL COMMUNICATIONS LTD<br />
            Account No: 53098072<br />
            Sort code: 60-14-12<br />
            VAT Reg. No. 805 4615 43
          </p>
        </div>
        <div class="cell small-12 medium-3">
          Net Total:<br />
          Vat:<br />
          Total:
        </div>
        <div class="cell small-12 medium-3">
          {{ invoice.netValue | currency }}<br />
          {{ invoice.vatValue | currency }}<br />
          {{ invoice.totalValue | currency }}
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-10">
        <div class="cell small-12">
          <p>
            Dice Medical Communications Ltd, The Yard, 14-18 Bell Street, Maidenhead, SL6 1BR
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicePrintView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      invoice: {
        accounts_contact: '',
        address: {},
        client: {
          invoice_parameters: [],
        },
        estimate: {
          brand: {},
          contact: {},
        },
        job_number: '',
      },
    };
  },
  methods: {
    print() {
      window.print();
    },
    goBack() {
      this.$router.go('-1');
    },
    getInvoice() {
      const invoiceId = this.$route.params.id;
      axios.get(`/invoices/getOne/${invoiceId}.json?token=${this.token}`)
        .then((response) => {
          this.invoice = response.data.invoice;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getInvoice();
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .preview-inner {
    margin: 10mm 5mm 10mm 5mm;
  }
}
</style>
