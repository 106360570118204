<template>
  <div class="grid-x grid-padding-x">
    <div class="cell small-2">
      <select v-model="date.day">
        <option
          :key="index"
          v-for="(day, index) in days"
          :value="day">{{ day }}</option>
      </select>
    </div>
    <div class="cell small-5">
      <select v-model="date.month">
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
    </div>
    <div class="cell small-4">
      <select v-model="date.year">
        <option
          v-for="(year, index) in years"
          :key="index"
          :value="year">{{ year }}</option>
      </select>
    </div>
    <div class="cell small-1">
      <span
        @click="$emit('saveDate')"
        title="Save Date"
        class="material-symbols-outlined icon-green icon-link"
        style="position: relative; top: 6px; right: 5px;">
        save</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateSelector',
  props: [
    'startDate',
  ],
  data() {
    return {
      date: {
        day: 1,
        month: 1,
        year: 2024,
      },
      days: [],
      years: [],
    };
  },
  watch: {
    date: {
      handler() {
        this.getDaysInMonth(this.date.year, this.date.month);
        this.$emit('dateChanged', this.date);
      },
      deep: true,
    },
  },
  methods: {
    getDaysInMonth(year, month) {
      this.days = new Date(year, month, 0).getDate();
    },
    getYears(currentYear) {
      const years = [];
      const lastYear = (currentYear - 1);
      const nextYear = (currentYear + 1);
      years.push(lastYear);
      years.push(currentYear);
      years.push(nextYear);
      this.years = years;
    },
    setDate() {
      const dateArray = this.startDate.split('-');
      // eslint-disable-next-line
      this.date.year = parseInt(dateArray[0], 10);
      // eslint-disable-next-line
      this.date.month = parseInt(dateArray[1], 10);
      // eslint-disable-next-line
      this.date.day = parseInt(dateArray[2], 10);
    },
  },
  mounted() {
    const year = new Date().getFullYear();
    const month = (new Date().getMonth() + 1);
    this.getDaysInMonth(year, month);
    this.getYears(year);
    setTimeout(() => {
      this.setDate();
    }, 200);
  },
};
</script>
