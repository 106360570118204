<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>View Invoice</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-8">
        <router-link :to="`/invoices/printview/${invoice.id}`">
          <button
            class="button small">Print view</button>&nbsp;
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-2 medium-offset-10">
        <img src="../../assets/logo.png" alt="Dice" />
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-1">
        To:
      </div>
      <div class="cell small-12 medium-4">
        <select v-model="invoice.accounts_contact">
          <option
            v-for="(contact, index) in invoice.client.invoice_parameters"
            :key="`c_${index}`">
            {{ contact.email }}
          </option>
        </select>
      </div>
      <div class="cell small-12 medium-1">
        <button @click="saveInvoice" class="button small">Save</button>
      </div>
      <div class="cell small-12 medium-2">Invoice no: </div>
      <div class="cell small-12 medium-4 text-right">{{ invoice.id }}</div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        {{ invoice.client.name }}
      </div>
      <div class="cell small-12 medium-2">
        Invoice date:
      </div>
      <div class="cell small-12 medium-4 text-right">
        {{ invoice.date | tinyDate }}
        <span
          @click="editDate = true"
          title="Update Date"
          class="material-symbols-outlined icon-yellow icon-link"
          style="position: relative; top: 5px;"
          v-if="editDate === false">
          edit</span>
      </div>
    </div>
    <div
        class="grid-x grid-padding-x pt-2"
        v-if="editDate === true">
      <div class="cell small-12 medium-7">&nbsp;</div>
      <div class="cell small-12 medium-5">
        <DateSelector :startDate="invoice.date" @dateChanged="updateDate" @saveDate="saveInvoice" />
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        {{ invoice.address.address_1 }}
        <span v-if="invoice.address.address_2"><br />{{ invoice.address.address_2 }}</span>
        <span v-if="invoice.address.address_3"><br />{{ invoice.address.address_3 }}</span>
        <span v-if="invoice.address.address_4"><br />{{ invoice.address.address_4 }}</span>
        <span v-if="invoice.address.address_5"><br />{{ invoice.address.address_5 }}</span>
        <span v-if="invoice.address.postcode"><br />{{ invoice.address.postcode }}</span>
        <span v-if="invoice.address.country"><br />{{ invoice.address.country }}</span>
      </div>
      <div class="cell small-12 medium-2">
        Our Job No:<br />
        <span v-if="invoice.estimate.po_number">
          Your order No:<br />
        </span>
        <span v-if="invoice.estimate.contact">
          Ordered by:
        </span>
      </div>
      <div class="cell small-12 medium-4 text-right">
        {{ invoice.job_number.job_number }}<br />
        <span v-if="invoice.estimate.po_number">
          {{ invoice.estimate.po_number }}<br />
        </span>
        <span v-if="invoice.estimate.contact">
          {{ invoice.estimate.contact.email }}
        </span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        Description:
      </div>
      <div class="cell small-12 medium-6">
        Amount:
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        {{ invoice.estimate.brand.name }}
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        {{ invoice.job_number.description }}
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12 medium-6">
        <p class="pb-4">
          Payment of all invoices is due within 30 days of the invoice date. Dice Medical
          Communications Ltd. reserves the right to invoice an interest charge of 3% per
          week on overdue amounts. Such invoices are payable within 7 days of the invoice date.
        </p>
        <p>
          For BACS payments please quote the following<br />
          Bank: NATWEST BANK PLC<br />
          Account name: DICE MEDICAL COMMUNICATIONS LTD<br />
          Account No: 53098072<br />
          Sort code: 60-14-12<br />
          VAT Reg. No. 805 4615 43
        </p>
      </div>
      <div class="cell small-12 medium-3">
        Net Total:<br />
        Vat:<br />
        Total:
      </div>
      <div class="cell small-12 medium-3">
        {{ invoice.netValue | currency }}<br />
        {{ invoice.vatValue | currency }}<br />
        {{ invoice.totalValue | currency }}
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-10">
      <div class="cell small-12">
        <p>
          Dice Medical Communications Ltd, The Yard, 14-18 Bell Street, Maidenhead, SL6 1BR
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';
import DateSelector from '../../components/DateSelector.vue';

export default {
  name: 'InvoicesView',
  components: {
    DateSelector,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      editDate: false,
      invoice: {
        accounts_contact: '',
        address: {},
        client: {
          invoice_parameters: [],
        },
        estimate: {
          brand: {},
          client: {},
          contact: {},
        },
        job_number: '',
      },
    };
  },
  methods: {
    getInvoice() {
      const invoiceId = this.$route.params.id;
      axios.get(`/invoices/getOne/${invoiceId}.json?token=${this.token}`)
        .then((response) => {
          this.invoice = response.data.invoice;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveInvoice() {
      const postData = this.invoice;
      axios.post(`/invoices/update/${this.invoice.id}.json?token=${this.token}`, postData)
        .then(() => {
          this.editDate = false;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    updateDate(value) {
      this.invoice.date = `${value.year}-${value.month}-${value.day}`;
    },
  },
  mounted() {
    setTimeout(() => {
      this.getInvoice();
    }, 100);
  },
};
</script>
